<template>
    <b-overlay :show="isLoading" rounded="sm">
        <div class="card card-custom card-stretch gutter-b">
            <div class="card-header border-0 pt-5">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder text-dark">Listado de alumnos</span>
                    <span class="text-muted mt-3 font-weight-bold font-size-sm">
            {{ totalRows }} en total
          </span>
                </h3>
                <div class="card-toolbar">
                </div>
            </div>
            <div class="card-body pb-10" data-app>
                <div class="row">
                    <div class="col-md-4">
                        <b-form-group
                                id="form-company-group"
                                label="Empresa"
                                label-for="input-2"
                        >
                            <b-form-select
                                    id="form-enterprise-field"
                                    v-model="filters.enterpriseId"
                                    :options="enterprises"
                                    required
                            ></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-md-4">
                        <b-form-group
                                id="form-training-group"
                                label="Training"
                                label-for="input-1"
                        >
                            <b-form-select
                                    id="form-training-field"
                                    v-model="filters.trainingId"
                                    :options="trainings"
                                    required
                            ></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-md-4">
                        <b-form-group
                                id="form-training-group"
                                label="Estado"
                                label-for="input-1"
                        >
                            <b-form-select
                                    id="form-training-field"
                                    v-model="filters.trainingStatusId"
                                    :options="trainingStatus"
                                    required
                            ></b-form-select>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <b-form-group
                                id="form-start_date-group"
                                label="Fecha de inicio"
                                label-for="input-3"
                        >
                            <b-form-datepicker
                                    id="form-start_date-field"
                                    v-model="filters.start_date"
                                    v-bind="labels[locale] || {}"
                                    :locale="locale"
                                    class="mb-2"
                            >
                            </b-form-datepicker>
                        </b-form-group>
                    </div>
                    <div class="col-md-4">
                        <b-form-group
                                id="form-end_date-group"
                                label="Fecha de finalización"
                                label-for="input-4"
                        >
                            <b-form-datepicker
                                    id="form-end_date-field"
                                    v-model="filters.end_date"
                                    v-bind="labels[locale] || {}"
                                    :locale="locale"
                                    class="mb-2"
                            >
                            </b-form-datepicker>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right">
                        <b-button type="button" variant="primary" @click="findStudents()">Buscar</b-button>
                        <b-button class="ml-2" type="button" variant="danger" @click="cleanFilters()">Limpiar</b-button>
                        <div style="width: 1px; height: 30px; border-right: 1px #b8c2cc solid;
                                        display: inline-flex; margin: 0px 2px 0px 10px; position: relative; top: 15px;"></div>
                        <b-button class="ml-2" type="button" variant="primary" @click="studentExportExcel()">Exportar</b-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <v-text-field
                                v-model="dtSearch"
                                label="Buscar"
                                append-icon="search"
                                class="mx-4"
                                single-line
                                hide-details
                        ></v-text-field>
                        <v-data-table
                                :headers="headers"
                                :items="list"
                                :options.sync="options"
                                :server-items-length="totalRows"
                                :loading="isLoadingTable"
                                :footer-props="{
                            itemsPerPageOptions: [5, 10, 25, 50],
                            showFirstLastPage: true,
                            itemsPerPageText: 'Resultados por página'
                        }"
                                :items-per-page="itemsPerPage"
                                class="elevation-1"
                                locale="es"
                        >
                            <template v-slot:item.order="{ item }">
                                <div class="text-left">
                                    <a @click="goOrderDetail(item.order)" style="cursor: pointer;">{{ item.order.training.name }}</a>
                                    <br/>
                                    <span style="font-size: xx-small">{{ item.order.start_date|formatDate }}-{{ item.order.end_date|formatDate }}</span>
                                </div>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <div class="text-center">
                                    <a @click="goDetail(item)" class="btn btn-icon btn-light btn-sm">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                          src="media/svg/icons/Design/ZoomMinus.svg"
                                  ></inline-svg>
                                </span>
                                    </a>
                                    <a v-if="item.traininginfo.canRestart" @click="goModify(item)" class="btn btn-icon btn-light btn-sm mx-3">
                                <span class="svg-icon svg-icon-md svg-icon-primary">
                                  <!--begin::Svg Icon-->
                                  <inline-svg
                                          src="media/svg/icons/Communication/Write.svg"
                                  ></inline-svg>
                                </span>
                                    </a>
                                </div>
                            </template>
                            <template slot="no-data"> Ningún dato para mostrar</template>
                        </v-data-table>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-delete" title="¿Estas seguro?">
            Los datos serán borrados de forma permanente
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button size="sm" variant="primary" @click="cancel()">
                    Cancelar
                </b-button>
                <b-button size="sm" variant="danger" @click="deleteItem(), ok()">
                    Aceptar
                </b-button>
            </template>
        </b-modal>
    </b-overlay>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import {STUDENTS_SET_SELECTED_STUDENT,
        STUDENTS_GET_STUDENTS_LIST,
        STUDENTS_EXPORT_STUDENTS_LIST,
        STUDENTS_GET_TRAININGS_LIST,
        STUDENTS_GET_ENTERPRISE_LIST,
        STUDENTS_GET_TRAINING_STATUS_LIST} from "@/store/modules/students.module";
    import {
        ORDERS_SET_SELECTED_ORDER_ID,
    } from "@/store/modules/orders.module";

    export default {
        name: "StudentsList",
        data() {
            return {
                currentPage: 1,
                itemsPerPage: 10,
                isLoading: false,
                isLoadingTable: false,
                options: {},
                idToDelete: 0,
                headers: [
                    {text: "RUT", value: "rut", align: " d-none", sortable: false},
                    {text: "DV", value: "dv", align: " d-none", sortable: false},
                    {text: "RUT", value: "fullRutWithHypend", align: "start", sortable: false},
                    {text: "Nombre", value: "name", sortable: false},
                    {text: "Apellido", value: "last_name", align: "start", sortable: false},
                    {text: "Empresa", value: "order.enterprise.razon_social_empresa", align: "start", sortable: false},
                    {text: "Orden", value: "order", sortable: false},
                    {text: "Estado", value: "traininginfo.status.name", sortable: false},
                    {text: "Acciones", value: "actions", align: "center", sortable: false},
                ],
                dtSearch: "",
                filters: {},
                locale: "es-ES",
                locales: [{value: "es-ES", text: "Español ES (es-ES)"}],
                labels: {
                    "es-ES": {
                        labelPrevDecade: "Década anterior",
                        labelPrevYear: "Año anterior",
                        labelPrevMonth: "El mes pasado",
                        labelCurrentMonth: "Mes actual",
                        labelNextMonth: "Próximo mes",
                        labelNextYear: "El próximo año",
                        labelNextDecade: "La próxima década",
                        labelToday: "hoy dia",
                        labelSelected: "Fecha seleccionada",
                        labelNoDateSelected: "Sin fecha elegida",
                        labelCalendar: "Calendario",
                        labelNav: "Navegación de calendario",
                        labelHelp:
                            "Utilice las teclas de flecha para navegar por el calendario",
                    },
                },
            };
        },
        mounted() {
            this.$store.commit(STUDENTS_SET_SELECTED_STUDENT, null)
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Alumnos", route: "#"},
                {title: "Listado de alumnos"},
            ]);
            this.$store.dispatch(STUDENTS_GET_TRAININGS_LIST, {
                page: this.currentPage,
                itemsPerPage: this.itemsPerPage
            })
            this.$store.dispatch(STUDENTS_GET_ENTERPRISE_LIST, {
                page: this.currentPage,
                itemsPerPage: this.itemsPerPage
            })
            this.$store.dispatch(STUDENTS_GET_TRAINING_STATUS_LIST, {
                page: this.currentPage,
                itemsPerPage: this.itemsPerPage
            })
        },
        methods: {
            findStudents(){
                this.getStudentList({
                    page: 1,
                    itemsPerPage: this.itemsPerPage
                })
            },
            cleanFilters(){
                this.filters = {}
            },
            getStudentList(dataPagination){
                let scope = this
                scope.isLoading = true
                this.$store.dispatch(STUDENTS_GET_STUDENTS_LIST, {
                    page: dataPagination.page,
                    itemsPerPage: dataPagination.itemsPerPage,
                    search: this.dtSearch,
                    filters: this.filters
                }).then(() => {
                    scope.isLoading = false
                })
            },
            goDetail(item) {
                this.$store.commit(STUDENTS_SET_SELECTED_STUDENT, item);
                this.$router.push("student-detail");
            },
            goModify(item) {
                this.$store.commit(STUDENTS_SET_SELECTED_STUDENT, item);
                this.$router.push("student-modify");
            },
            studentExportExcel(){
                let scope = this
                scope.isLoading = true
                this.$store.dispatch(STUDENTS_EXPORT_STUDENTS_LIST, {
                    filters: {
                        search: this.dtSearch,
                        ... scope.filters,
                    }
                }).then(() => {
                    scope.isLoading = false
                })
            },
            goOrderDetail(order) {
                this.$store.commit(ORDERS_SET_SELECTED_ORDER_ID, order.id);
                this.$router.push("order-detail");
            },
        },
        computed: {
            totalRows() {
                return this.$store.state.students.studentsListTotal;
            },
            list() {
                return this.$store.state.students.studentsList;
            },
            enterprises() {
                return this.$store.state.students.studentEnterpriseList.map((item) => {
                    return {text: item.razon_social_empresa, value: item.id};
                });
            },
            trainings() {
                return this.$store.state.students.studentTrainingList.map((item) => {
                    return {text: item.name, value: item.id};
                });
            },
            trainingStatus() {
                return this.$store.state.students.studentTrainingStatusList.map((item) => {
                    return {text: item.name, value: item.id};
                });
            },
        },
        watch: {
            options: {
                handler (dataPagination) {
                    this.getStudentList(dataPagination)
                },
                deep: true,
            },
            dtSearch(){
                this.getStudentList({
                    page: 1,
                    itemsPerPage: this.itemsPerPage
                })
            },
        }
    };
</script>

<style>
    .v-data-footer__select .v-select{
        padding: 15px!important;
    }
</style>
